<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="searchRef" :fields="this.search.fields" :card="this.search.card" @change="searchChange">
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          
          <b-tab title="Prediciones Diarias">
            <predicciones-diarias-tab :planta_selected="planta_selected"></predicciones-diarias-tab>
          </b-tab>
          
          <b-tab title="Histórico Predicciones Peso Promedio Diario">
            <historico-predicciones-tab :planta_selected="planta_selected"></historico-predicciones-tab>
          </b-tab>

          <b-tab title="Histórico Predicciones Distribución Peso">
            <historico-distribucion-peso :planta_selected="planta_selected"></historico-distribucion-peso>
          </b-tab>


        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
          
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Stat from '@/views/clients/base/components/Stat.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import DashboardTab from './tabs/DashboardTab.vue'
import ConsumoTab from './tabs/ConsumoTab.vue'

import PrediccionesDiariasTab from './tabs/PrediccionesDiarias.vue'
import HistoricoPrediccionesTab from './tabs/HistoricoPrediccionesTab.vue'

import HistoricoDistribucionPeso from './tabs/HistoricoDistribucionPeso.vue'


import axios from "@axios";
var qs = require('qs');

import {
BRow,
BCol,
BButtonToolbar,
BButtonGroup,
BButton,
BInputGroup,
BFormInput,
BFormGroup,
BFormTextarea,

BCard,
BCardTitle,
BCardSubTitle,
BCardBody,

BMedia,
BMediaAside,
BAvatar,
BMediaBody,

BOverlay,
BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Stat,
    BOverlay,
    BSpinner,
    BrunaForm,
    BrunaElementLoader,
    DashboardTab,
    ConsumoTab,

    PrediccionesDiariasTab,
    HistoricoPrediccionesTab,
    HistoricoDistribucionPeso
  },

  methods: {

    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },

    searchChange(field) {
      if (field.id == "planta") {
        this.planta_selected = field.value;

        let keys = Object.keys(this.searchResults[field.value]);
        keys.sort();

        this.plantas = keys

      }

    },

    loadSearch() {
      let options = [
        { value: null, text: "Selecciona una opción" }
      ]
      options.push({ value: 'Planta Rosario', text: "Planta Rosario" })

      let mdf_field = this.findFieldByName(this.search.fields, 'planta')

      mdf_field.options = options;
      mdf_field.value = 'Planta Rosario';
    }


  },

  mounted() {
    this.loadSearch();
  },

  created() {


  },


  data() {

    let debug = (this.$route.query.debug == '1')


    return {

      debug: debug,


      searchResults: [],
      planta_selected: null,

      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
            title: "Búsqueda",
            body: 'Seleccione la planta de la que desea visualizar las predicciones.'
          }
        },
        fields: [


          {
            label: "Planta",
            type: "select",
            id: 'planta',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
        ]
      },
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
        
        
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
        
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;
      .list-inline {
        display: block;
        li {
          a {
            padding: 0;
          }
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
          